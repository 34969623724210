<script setup lang="ts">
  import { useAppStore } from '@/stores/app'

  const app = useAppStore()
</script>

<template>
  <div class="text-lg flex gap-x-2">
    <a
      v-if="app.linkYoutube"
      :href="app.linkYoutube"
      target="_blank"
      rel="noopener noreferrer">
      <fa-icon icon="fab youtube" />
    </a>
    <a
      v-if="app.linkInstagram"
      :href="app.linkInstagram"
      target="_blank"
      rel="noopener noreferrer">
      <fa-icon icon="fab instagram" />
    </a>
    <a
      v-if="app.linkFacebook"
      :href="app.linkFacebook"
      target="_blank"
      rel="noopener noreferrer">
      <fa-icon icon="fab square-facebook" />
    </a>
  </div>
</template>

<style lang="postcss" scoped>
  a {
    @apply focus-visible:ring-white;
  }
</style>